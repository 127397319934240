import React, { useRef } from 'react';

import { ChevronDown } from '../../Icons';
import { OverridableComponent, Overwrite, PolymorphicProps, Simplify } from '../../types';
import { Box, BoxProps, SprinklesProps } from './Box';
import { Flex, FlexProps } from './Flex';
import { SelectVariants, selectRecipe } from './select.css';

type SelectVariantsWithSprinkles = Simplify<Overwrite<SprinklesProps, SelectVariants>>;

type Props = SelectVariantsWithSprinkles & {
  disabled?: boolean;
  hasError?: boolean;
  children?: React.ReactNode;
  icon?: React.ReactNode | JSX.Element;
  iconProps?: BoxProps<'div'>;
  chevronSize?: string;
  containerProps?: Omit<FlexProps<'div'>, 'align' | 'width' | 'position' | 'flex'>;
};

type SelectTypeMap = {
  props: Props;
  defaultComponent: 'select';
};

export type SelectProps<Root extends React.ElementType = SelectTypeMap['defaultComponent']> =
  PolymorphicProps<SelectTypeMap, Root>;

function SelectImpl(props: Omit<SelectProps, 'element'>, ref: React.ForwardedRef<Element>) {
  const {
    variant,
    size,
    hasError,
    children,
    icon,
    iconProps,
    chevronSize,
    containerProps,
    className,
    ...restProps
  } = props;

  const selectRef = useRef<HTMLSelectElement | null>(null);
  const selectClassName = selectRecipe({ variant, size, error: hasError });

  return (
    <Flex
      align="center"
      borderRadius="base"
      width="full"
      position="relative"
      flex="1"
      background={variant === 'filled' ? 'gray' : 'white'}
      {...containerProps}
    >
      <Box
        element="select"
        ref={(node: any) => {
          selectRef.current = node;
          if (ref && 'current' in ref && ref.current) {
            ref.current = node;
          }
        }}
        className={[selectClassName, className].join(' ')}
        zIndex="docked"
        paddingRight="12px"
        textTransform="capitalize"
        {...restProps}
      >
        {children}
      </Box>

      <Box
        width="fitContent"
        minWidth="20px"
        display="flex"
        zIndex="base"
        alignItems="center"
        position="absolute"
        top="0px"
        right="0px"
        style={{
          height: '100%',
          right: chevronSize ? `-${+chevronSize.replace(/px/g, '')}px` : '8px',
          ...iconProps?.style,
        }}
        {...iconProps}
      >
        {icon ?? <ChevronDown size={chevronSize ?? '20px'} />}
      </Box>
    </Flex>
  );
}

export const Select = React.memo(
  React.forwardRef(SelectImpl),
) as OverridableComponent<SelectTypeMap>;
