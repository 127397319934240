import * as React from 'react';

import { Sprinkles } from '../../sprinkles.css';
import { OverridableComponent, Overwrite, PolymorphicProps, Simplify } from '../../types';
import { Box, SprinklesProps } from './Box';
import { TextVariants, textRecipe } from './text.css';

type TextVariantsWithSprinkles = Simplify<Overwrite<SprinklesProps, TextVariants>>;

type Props = TextVariantsWithSprinkles & {
  disableLinkStyle?: boolean;
  align?: Sprinkles['textAlign'];
  weight?: Sprinkles['fontWeight'];
  size?: Sprinkles['fontSize'];
};

type TextTypeMap = {
  props: Props;
  defaultComponent: 'p';
};

export type TextProps<Root extends React.ElementType = TextTypeMap['defaultComponent']> =
  PolymorphicProps<TextTypeMap, Root>;

function TextImpl(props: TextProps, ref: React.ForwardedRef<Element>) {
  const {
    element,
    align = 'left',
    weight = 'normal',
    fontSize,
    variant,
    color,
    disableLinkStyle,
    className,
    children,
    ...restProps
  } = props;

  // Use `variant='unstyled'` so bypass recipe. This allows for full customization
  // via style props. Note that the `unstyled` variant is not part of the recipe and
  // is only used here as a flag to bypass the recipe.
  const noVariant = variant === 'unstyled' || !variant;
  let _className = noVariant ? '' : textRecipe({ variant });

  const isHigherHeading = element === 'h1' || element === 'h2';

  return (
    <Box
      ref={ref}
      className={[_className, className].join(' ')}
      element={element}
      fontSize={fontSize}
      fontFamily="body"
      lineHeight="base"
      fontWeight={weight}
      letterSpacing="normal"
      color={!color && isHigherHeading ? 'primary' : color ?? 'inherit'}
      textAlign={align}
      {...restProps}
    >
      {children}
    </Box>
  );
}

export const Text = React.memo(React.forwardRef(TextImpl)) as OverridableComponent<TextTypeMap>;
