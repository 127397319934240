import { ReactNode, forwardRef } from 'react';

import { Box, BoxProps, Button, Flex, Stack, Text, useDisclosure } from '../..';
import { CloseIcon } from '../../Icons';
import ScreenOverlay from '../ScreenOverlay.tsx';
import { ModalRecipe, ModalVariants } from './styles.css';

type StyleProps = {} & ModalVariants;

export interface ModalProps
  extends Omit<BoxProps<'div'>, 'children' | 'title'>,
    Omit<ReturnType<typeof useDisclosure>, 'onToggle' | 'onOpen'> {
  children: ReactNode;
  fixedUpperContent?: ReactNode;
  variant?: StyleProps['variant'];
  title?: ReactNode;
  scrollable?: boolean;
}

const Modal = forwardRef<HTMLDivElement, ModalProps>(
  (
    { variant, isOpen, onClose, children, title, scrollable, fixedUpperContent, ...props },
    scrollBoxRef,
  ) => {
    const modalClassName = ModalRecipe({
      variant,
      isOpen: isOpen,
    });

    return (
      <ScreenOverlay
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
        {...{ onClose, isOpen }}
      >
        <Box
          className={modalClassName}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          {...props}
        >
          <Box flex="1" width="full" backgroundColor="transparent" onClick={onClose} />
          <Flex
            flexDirection="column"
            minWidth="250px"
            minHeight="100px"
            borderRadius="base"
            backgroundColor="white"
            overflow="hidden"
            maxHeight="threeFourth"
          >
            <Stack
              direction="row"
              justifyContent={title ? 'space-between' : 'flex-end'}
              alignItems="center"
              paddingLeft="12px"
              width="full"
              backgroundColor="primary"
            >
              {title && (typeof title === 'string' ? <Text color="white">{title}</Text> : title)}
              <Button onClick={onClose} aria-label="Close">
                <CloseIcon color="white" size="20px" />
              </Button>
            </Stack>
            {fixedUpperContent && (
              <Box padding="12px" width="full" borderBottom="1px" borderColor="border">
                {fixedUpperContent}
              </Box>
            )}
            <Box
              padding="12px"
              ref={scrollBoxRef}
              width="full"
              overflowY={scrollable ? 'scroll' : undefined}
            >
              {children}
            </Box>
          </Flex>
          <Box flex="1" width="full" backgroundColor="transparent" onClick={onClose} />
        </Box>
      </ScreenOverlay>
    );
  },
);

export default Modal;
