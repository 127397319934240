import { ReactNode, createContext, useContext } from 'react';

import { enableStaticRendering } from 'mobx-react';

import { RootStore, RootStoreHydration } from './rootStore';

enableStaticRendering(typeof window === 'undefined');

let store: RootStore;
const StoreContext = createContext<RootStore | undefined>(undefined);
StoreContext.displayName = 'StoreContext';

export function useRootStore() {
  const context = useContext(StoreContext);
  if (context === undefined) {
    throw new Error('useRootStore must be used within RootStoreProvider');
  }

  return context;
}

export function useProductStore() {
  const { productStore } = useRootStore();
  return productStore;
}

export function useCartStore() {
  const { cartStore } = useRootStore();
  return cartStore;
}

export function useCheckoutStore() {
  const { checkoutStore } = useRootStore();
  return checkoutStore;
}

export interface RootStoreProviderProps {
  children: ReactNode;
  hydrationData?: RootStoreHydration;
}

export function RootStoreProvider({ children, hydrationData }: RootStoreProviderProps) {
  const store = initializeStore(hydrationData);

  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
}

function initializeStore(initialData?: RootStoreHydration): RootStore {
  const _store = store ?? new RootStore({});

  if (initialData) {
    _store.hydrate(initialData);
  }
  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
}
