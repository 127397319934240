import Link from 'next/link';

import { observer } from 'mobx-react';

import AeribellaLogo from '../../public/assets/AeribellaLogo.svg';
import AeribellaIconLogo from '../../public/assets/aeribella-icon-logo.svg';
import AeribellaInvertedIconLogo from '../../public/assets/inverted-logo-icon.svg';
import { useProductStore } from '../../store/rootStoreProvider';
import { Routes, companyName } from '../../utils/const';
import { Box, BoxProps } from '../Design';

interface LogoProps extends BoxProps<'div'> {
  isIcon?: boolean;
  center?: boolean;
  inverted?: boolean;
  logoContainerClassName?: string;
  logoClass?: string;
  logoHeight?: string;
  logoWidth?: string;
  disableLink?: boolean;
}

// TODO: Update Animation
const Logo = ({
  isIcon,
  center,
  inverted,
  logoContainerClassName,
  logoClass,
  logoHeight,
  logoWidth,
  disableLink,
  ...props
}: LogoProps) => {
  const { setFilterValues } = useProductStore();
  return (
    <Box
      {...(center && { display: 'flex', alignContent: 'center', justifyContent: 'center' })}
      zIndex="banner"
      {...props}
    >
      <Box
        element={disableLink ? 'div' : Link}
        onClick={() => {
          setFilterValues([]);
        }}
        aria-label={`${companyName} LLC Home Page`}
        className={logoContainerClassName}
        style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}
        {...(disableLink ? {} : { href: Routes.home })}
      >
        {isIcon ? (
          inverted ? (
            <AeribellaInvertedIconLogo height={logoHeight} width={logoWidth} />
          ) : (
            <AeribellaIconLogo height={logoHeight} width={logoWidth} />
          )
        ) : (
          <AeribellaLogo
            height={logoHeight}
            width={logoWidth}
            className={['aeribella-logo', logoClass].join(' ')}
          />
        )}
      </Box>
    </Box>
  );
};

export default observer(Logo);
