import { action, computed, flow, makeObservable, observable } from 'mobx';

import { getShopifyCollectionByHandle } from '../pages/api/shopify/products/collection';
import { getShopifyProductByHandle } from '../pages/api/shopify/products/product';
import { getShopifyProductRecommendations } from '../pages/api/shopify/products/product-recommendations';
import {
  ShopifyProductsSearchBody,
  productsSearch,
  sortByDefaults,
} from '../pages/api/shopify/products/products-search';
import { CollectionData, ProductData, ProductSearchData, StoreModel } from '../types/store';
import { cleanSearchQuery, convertProductArrayToByHandle } from '../utils';
import {
  CategoryOptionsValues,
  KnownProductTypes,
  apiErrorMessage,
  defaultCategoryOptionsValues,
} from '../utils/const';
import { RootStore } from './rootStore';

export interface ProductHydration {
  collectionByHandle?: Record<string, CollectionData>;
  productsByHandle?: Record<string, ProductData>;
  searchResults?: ProductData[] | [] | null;
  searchPagination?: ProductSearchData['pageInfo'] | null;
  searchQuery?: string;
  newOrchidArrivals?: ProductData[];
  currentSearchConditions?: ShopifyProductsSearchBody;
  loading?: boolean;
  filterValues?: string[] | null;
  productsPerPage?: '16' | '32' | '64';
  sortBy?: keyof typeof sortByDefaults;
  category?: CategoryOptionsValues | null;
  currentSearchFormValue?: string | null;
  currentPage?: number;
}

export class ProductStore implements StoreModel {
  rootStore: RootStore;
  collectionByHandle: ProductHydration['collectionByHandle'];
  productsByHandle: ProductHydration['productsByHandle'];
  searchResults: ProductHydration['searchResults'];
  searchQuery: ProductHydration['searchQuery'];
  searchPagination: ProductHydration['searchPagination'];
  newOrchidArrivals: ProductHydration['newOrchidArrivals'];
  currentSearchConditions: ProductHydration['currentSearchConditions'];
  loading: boolean;
  filterValues: ProductHydration['filterValues'];
  productsPerPage: ProductHydration['productsPerPage'];
  sortBy: ProductHydration['sortBy'];
  category: ProductHydration['category'];
  currentSearchFormValue: ProductHydration['currentSearchFormValue'];
  currentPage: ProductHydration['currentPage'];

  constructor(
    rootStore: RootStore,
    initialState: ProductHydration = {
      collectionByHandle: {},
      productsByHandle: {},
      searchResults: [],
      searchPagination: undefined,
      productsPerPage: '16',
      searchQuery: '',
      newOrchidArrivals: [],
      currentSearchConditions: undefined,
      loading: false,
      filterValues: undefined,
      sortBy: 'New Arrivals',
      category: defaultCategoryOptionsValues,
      currentSearchFormValue: '',
      currentPage: 1,
    },
  ) {
    this.rootStore = rootStore;
    this.collectionByHandle = initialState.collectionByHandle;
    this.productsByHandle = initialState.productsByHandle;
    this.searchResults = initialState.searchResults;
    this.searchPagination = initialState.searchPagination;
    this.productsPerPage = initialState.productsPerPage;
    this.searchQuery = initialState.searchQuery;
    this.newOrchidArrivals = initialState.newOrchidArrivals;
    this.currentSearchConditions = initialState.currentSearchConditions;
    this.currentSearchFormValue = initialState.currentSearchFormValue;
    this.loading = !!initialState.loading;
    this.filterValues = initialState.filterValues;
    this.sortBy = initialState.sortBy;
    this.category = initialState.category;
    this.currentPage = initialState.currentPage;

    makeObservable(this, {
      collectionByHandle: observable,
      productsByHandle: observable,
      searchResults: observable,
      searchPagination: observable,
      productsPerPage: observable,
      searchQuery: observable,
      newOrchidArrivals: observable,
      loading: observable,
      filterValues: observable,
      sortBy: observable,
      category: observable,
      currentSearchConditions: observable,
      currentSearchFormValue: observable,
      currentPage: observable,
      getCollectionByHandle: flow.bound,
      getProductByHandle: flow.bound,
      getHeatPack: flow.bound,
      productSearch: flow.bound,
      getMultiProductsByHandle: flow.bound,
      getProductRecommendations: flow.bound,
      getNewArrivalByCategory: flow.bound,
      getProductHandlesFromCollection: action,
      setProductsPerPage: action.bound,
      setFilterValues: action.bound,
      setSortBy: action.bound,
      setCategory: action.bound,
      clearSearchQuery: action.bound,
      hydrate: action,
      setCurrentSearchFormValue: action.bound,
      setCurrentPage: action.bound,
      numberOfSearchResults: computed,
    });
  }

  get asJson() {
    return {
      collectionByHandle: this.collectionByHandle,
      productsByHandle: this.productsByHandle,
      searchResults: this.searchResults,
      searchQuery: this.searchQuery,
      newOrchidArrivals: this.newOrchidArrivals,
      currentSearchConditions: this.currentSearchConditions,
      currentSearchFormValue: this.currentSearchFormValue,
      loading: this.loading,
      filterValues: this.filterValues,
      searchPagination: this.searchPagination,
      productsPerPage: this.productsPerPage,
      sortBy: this.sortBy,
      category: this.category,
      currentPage: this.currentPage,
      numberOfSearchResults: this.numberOfSearchResults,
    };
  }

  get numberOfSearchResults() {
    return this.searchPagination?.totalCounts?.products;
  }

  hydrate(data?: ProductHydration) {
    if (data) {
      this.collectionByHandle = { ...this.collectionByHandle, ...data.collectionByHandle };
      this.productsByHandle = { ...this.productsByHandle, ...data.productsByHandle };
      this.searchResults = data.searchResults ?? this.searchResults;
      this.currentPage = data.currentPage ?? this.currentPage;
      const searchPage = data.searchPagination ?? this.searchPagination;
      if (searchPage) {
        this.searchPagination = {
          ...searchPage,
          totalCounts: searchPage?.totalCounts ?? this.searchPagination?.totalCounts,
        };
      }
      this.newOrchidArrivals = data.newOrchidArrivals ?? this.newOrchidArrivals;
      this.currentSearchConditions = data.currentSearchConditions ?? this.currentSearchConditions;
      this.filterValues = data.filterValues ?? this.filterValues;
      this.searchQuery = data.searchQuery ?? this.searchQuery ?? '';
      this.sortBy = data.sortBy ?? this.sortBy;
      this.category = data.category ?? this.category;
      this.currentSearchFormValue = data.currentSearchFormValue ?? this.currentSearchFormValue;
    }
  }

  clearSearchQuery() {
    this.searchQuery = '';
  }

  setCurrentPage(pageNumber: number) {
    this.currentPage = pageNumber;
  }

  setCurrentSearchFormValue(value?: string) {
    this.currentSearchFormValue = cleanSearchQuery(value ?? '');
  }

  getProductHandlesFromCollection(collection?: CollectionData) {
    const handles = collection?.products?.map(product => product?.handle ?? undefined);
    return handles?.filter((handle): handle is string => typeof handle === 'string');
  }

  setProductsPerPage(num?: ProductHydration['productsPerPage']) {
    this.productsPerPage = num;
  }

  setFilterValues(values?: ProductHydration['filterValues']) {
    this.filterValues = values;
  }

  setSortBy(value?: ProductHydration['sortBy']) {
    this.sortBy = value;
  }

  setCategory(value?: KnownProductTypes) {
    this.category = value ?? defaultCategoryOptionsValues;
  }

  *getCollectionByHandle(handle: string) {
    this.loading = true;
    const collection: CollectionData | null | undefined =
      yield getShopifyCollectionByHandle(handle);

    if (collection instanceof Error) {
      this.rootStore.setGlobalError(apiErrorMessage('retrieving the product collection'));
      this.loading = false;
      throw collection;
    }

    if (collection?.handle) {
      this.collectionByHandle = { ...this.collectionByHandle, [collection?.handle]: collection };
    }

    this.loading = false;
  }

  *getHeatPack() {
    this.loading = true;
    const product: ProductData | null | undefined = yield getShopifyProductByHandle('heat-pack');

    if (product instanceof Error) {
      this.rootStore.setGlobalError(apiErrorMessage('retrieving the heat pack product data'));
      this.loading = false;
      throw product;
    }

    if (product?.handle) {
      this.productsByHandle = { ...this.productsByHandle, [product?.handle]: product };
    }

    this.loading = false;
  }

  *getNewArrivalByCategory(body: ShopifyProductsSearchBody, category?: KnownProductTypes) {
    this.loading = true;

    const bodyHandler: ShopifyProductsSearchBody = { ...body };

    this.searchQuery = body?.query ?? '';
    const productSearch: ProductSearchData = yield productsSearch(
      bodyHandler,
      category ?? this.category ?? KnownProductTypes?.all,
    );

    if (productSearch instanceof Error) {
      this.rootStore.setGlobalError(apiErrorMessage('searching for products'));
      this.loading = false;
      throw productSearch;
    }

    const products = productSearch?.products;
    const newOrchidArrivals = productSearch?.products;
    const productsByHandle: Record<string, ProductData> | undefined =
      (products && convertProductArrayToByHandle(products)) ?? undefined;

    this.newOrchidArrivals = newOrchidArrivals;
    this.productsByHandle = { ...this.productsByHandle, ...productsByHandle };
    // this.searchResults = products;

    this.loading = false;
  }

  *productSearch(body: ShopifyProductsSearchBody, product_type?: KnownProductTypes) {
    this.loading = true;
    const firstOrLast = body?.first
      ? {
          first: (this.productsPerPage && +this.productsPerPage) ?? 16,
          last: undefined,
          before: undefined,
        }
      : { last: this.productsPerPage && +this.productsPerPage, first: undefined };

    if (!body?.disableTotalCounts) this.setCurrentPage(1);

    const bodyHandler = { ...body, ...firstOrLast };

    this.currentSearchConditions = { ...bodyHandler, disableTotalCounts: undefined };
    this.searchQuery = body?.query ?? '';
    this.category = product_type ?? this.category ?? KnownProductTypes.all;
    let productSearch: ProductSearchData = yield productsSearch(
      bodyHandler,
      product_type ?? this.category ?? KnownProductTypes.all,
    );
    let failedAttempts = 0;

    if (productSearch instanceof Error) {
      failedAttempts = 1;
      productSearch = yield productsSearch(
        bodyHandler,
        product_type ?? this.category ?? KnownProductTypes.all,
      );
    }

    if (productSearch instanceof Error && failedAttempts === 1) {
      failedAttempts = 2;
      productSearch = yield productsSearch(
        bodyHandler,
        product_type ?? this.category ?? KnownProductTypes.all,
      );
    }

    if (productSearch instanceof Error && failedAttempts === 2) {
      this.rootStore.setGlobalError(apiErrorMessage('searching for products'));
      this.loading = false;
      throw productSearch;
    }

    const products = productSearch?.products;
    const pageInfo = productSearch?.pageInfo;

    const searchPage = pageInfo ?? this.searchPagination;

    if (searchPage) {
      this.searchPagination = {
        ...searchPage,
        totalCounts: searchPage?.totalCounts ?? this.searchPagination?.totalCounts,
      };
    }

    const productsByHandle: Record<string, ProductData> | undefined =
      (products && convertProductArrayToByHandle(products)) ?? undefined;

    this.productsByHandle = { ...this.productsByHandle, ...productsByHandle };
    this.searchResults = products;

    this.loading = false;
  }

  *getProductByHandle(handle: string) {
    this.loading = true;
    const product: ProductData | null | undefined = yield getShopifyProductByHandle(handle);

    if (product instanceof Error) {
      this.rootStore.setGlobalError(apiErrorMessage('retrieving the product data'));
      this.loading = false;
      throw product;
    }

    if (product?.handle) {
      this.productsByHandle = { ...this.productsByHandle, [product?.handle]: product };
    }

    this.loading = false;
  }

  *getProductRecommendations(productId: string) {
    this.loading = true;

    const hasRecommendation =
      this.productsByHandle &&
      Object.values(this.productsByHandle)?.find(({ id }) => id === productId)?.recommended;

    if (hasRecommendation) return;

    const products: ProductData[] | null | undefined =
      yield getShopifyProductRecommendations(productId);

    if (products instanceof Error) {
      this.rootStore.setGlobalError(apiErrorMessage('retrieving recommended products'));
      this.loading = false;
      throw products;
    }

    const addProductsAsNewByHandleProduct: ProductHydration['productsByHandle'] = {};
    products?.forEach?.(product => {
      if (product?.handle) addProductsAsNewByHandleProduct[product?.handle] = product;
    });

    const currentProductHandle =
      this.productsByHandle &&
      Object.values(this.productsByHandle)?.find(
        productByHandleValue => productByHandleValue?.id === productId,
      )?.handle;

    if (currentProductHandle && products && this.productsByHandle?.[currentProductHandle]) {
      this.productsByHandle = {
        ...this.productsByHandle,
        [currentProductHandle]: {
          ...this.productsByHandle[currentProductHandle],
          recommended: products,
        },
      };
      this.loading = false;
      return;
    }

    if (Object.values(addProductsAsNewByHandleProduct)?.length >= 1) {
      this.productsByHandle = { ...this.productsByHandle, ...addProductsAsNewByHandleProduct };
    }

    this.loading = false;
  }

  *getMultiProductsByHandle(handles: string[]) {
    this.loading = true;
    handles?.forEach(async handle => {
      if (!this.productsByHandle?.[handle]) {
        const product = await getShopifyProductByHandle(handle);

        if (product instanceof Error) {
          this.rootStore.setGlobalError(apiErrorMessage('retrieving multiple product data'));
          this.loading = false;
          throw product;
        }

        if (product?.handle)
          this.productsByHandle = { ...this.productsByHandle, [product?.handle]: product };
      }
    });

    this.loading = false;
  }
}
