import { NextApiRequest, NextApiResponse } from 'next';

import { BASE_URL, apiError } from '../../../pages/api/utils';
import {
  CHECKOUT_ERROR_FRAGMENT,
  CHECKOUT_FRAGMENT,
  ShopifyFragmentsNames,
  shopifyStoreFront,
  transformCheckout,
} from '../../../pages/api/utils/shopifyApi';
import { CheckoutRes } from '../../../types/store';

const updateAddressQuery = `
${CHECKOUT_FRAGMENT}
${CHECKOUT_ERROR_FRAGMENT}
mutation checkoutShippingAddressUpdateV2($checkoutId: ID!, $shippingAddress: MailingAddressInput!) {
  checkoutShippingAddressUpdateV2(checkoutId: $checkoutId, shippingAddress: $shippingAddress) {
    checkout {
      ...${ShopifyFragmentsNames.CHECKOUT_FRAGMENT}
    }
    checkoutUserErrors {
      ...${ShopifyFragmentsNames.CHECKOUT_ERROR_FRAGMENT}
    }  
  }
}
`;

const shopifyUpdateAddress = async (req: NextApiRequest, res: NextApiResponse) => {
  const { body } = req;

  try {
    let response = await shopifyStoreFront(updateAddressQuery, JSON.parse(body));

    res.status(200);
    res.json(transformCheckout(response?.checkoutShippingAddressUpdateV2));
  } catch (error) {
    apiError({
      errorInText: 'update address - checkout',
      error,
      res,
      resJsonKey: 'checkoutUserErrors',
    });
  }
};

export interface ShippingAddressData {
  address1: string;
  address2?: string;
  city: string;
  company?: string;
  country: string;
  firstName: string;
  lastName: string;
  phone: string;
  province?: string;
  zip: string;
}
export interface UpdateAddressBody {
  checkoutId: string;
  shippingAddress: ShippingAddressData;
}

export const updateAddress = async (body: UpdateAddressBody): Promise<CheckoutRes | undefined> => {
  try {
    const response = await fetch(`${BASE_URL}/api/shopify/checkout/update-address`, {
      method: 'POST',
      body: JSON.stringify(body),
    });

    const resJson = await response?.json();

    return resJson;
  } catch (e) {
    console.error('updateAddress failed', e);
  }
};

export default shopifyUpdateAddress;
