import { NextRequest } from 'next/server';

import { BASE_URL, apiError } from '../../utils';
import {
  PRODUCT_FRAGMENT,
  ShopifyFragmentsNames,
  cleanGraphQLResponse,
  shopifyStoreFront,
  transformProduct,
} from '../../utils/shopifyApi';

export const config = {
  runtime: 'edge',
};

const productByHandleQuery = `
${PRODUCT_FRAGMENT}
query ProductByHandle($handle: String!) {
  product(handle: $handle) {
    ...${ShopifyFragmentsNames.PRODUCT_FRAGMENT}
  }
}
`;

const shopifyProductByHandle = async (req: NextRequest) => {
  const { searchParams } = new URL(req.url);
  const productHandle = searchParams.get('productHandle');
  try {
    let response = await shopifyStoreFront(productByHandleQuery, { handle: productHandle });

    response = response ? cleanGraphQLResponse(response)?.product : null;

    return new Response(JSON.stringify(response), {
      status: 200,
      headers: {
        'content-type': 'application/json',
      },
    });
  } catch (error) {
    apiError({
      errorInText: 'PRODUCT BY HANDLE',
      error,
      isEdge: true,
    });
  }
};

export const getShopifyProductByHandle = async (productHandle: string) => {
  try {
    const response = await fetch(
      `${BASE_URL}/api/shopify/products/product?productHandle=${productHandle}`,
    );

    const resJson = await response?.json();

    return resJson ? transformProduct(resJson) : null;
  } catch (e: any) {
    console.error('getShopifyProductByHandle failed', e);
    if (e) return e;
  }
};

export default shopifyProductByHandle;
