import { NextApiRequest, NextApiResponse } from 'next';

import { BASE_URL, apiError } from '../../../pages/api/utils';
import {
  CHECKOUT_ERROR_FRAGMENT,
  CHECKOUT_FRAGMENT,
  ShopifyFragmentsNames,
  shopifyStoreFront,
  transformCheckout,
} from '../../../pages/api/utils/shopifyApi';
import { CheckoutRes } from '../../../types/store';

const addLineItemsQuery = `
${CHECKOUT_FRAGMENT}
${CHECKOUT_ERROR_FRAGMENT}
mutation checkoutLineItemsAdd($checkoutId: ID!, $lineItems: [CheckoutLineItemInput!]!) {
  checkoutLineItemsAdd(checkoutId: $checkoutId, lineItems: $lineItems) {
    checkout {
      ...${ShopifyFragmentsNames.CHECKOUT_FRAGMENT}
    }
    checkoutUserErrors {
      ...${ShopifyFragmentsNames.CHECKOUT_ERROR_FRAGMENT}
    }  
  }
}
`;

const shopifyAddLineItems = async (req: NextApiRequest, res: NextApiResponse) => {
  const { body } = req;

  try {
    let response = await shopifyStoreFront(addLineItemsQuery, JSON.parse(body));

    res.status(200);
    res.json(transformCheckout(response?.checkoutLineItemsAdd));
  } catch (error) {
    apiError({
      errorInText: 'add line item - checkout',
      error,
      res,
      resJsonKey: 'checkoutUserErrors',
    });
  }
};

export interface AddLineItemsBody {
  checkoutId: string;
  lineItems: {
    customAttributes?: {
      key: string;
      value: string;
    }[];
    quantity: number;
    variantId: string;
  }[];
}

export const addLineItems = async (body: AddLineItemsBody): Promise<CheckoutRes | undefined> => {
  try {
    const response = await fetch(`${BASE_URL}/api/shopify/checkout/add-line-item`, {
      method: 'POST',
      body: JSON.stringify(body),
    });

    const resJson = await response?.json();

    return resJson;
  } catch (e) {
    console.error('addLineItems failed', e);
  }
};

export default shopifyAddLineItems;
