import { NextApiRequest, NextApiResponse } from 'next';

import { CreateCartData } from '../../../../types/store';
import { BASE_URL, apiError } from '../../utils';
import {
  CART_FRAGMENT,
  ShopifyFragmentsNames,
  cleanGraphQLResponse,
  shopifyStoreFront,
} from '../../utils/shopifyApi';

const createCartQuery = `
${CART_FRAGMENT}
mutation cartCreate {
  cartCreate {
    cart {
     ...${ShopifyFragmentsNames.CART_FRAGMENT}
    }
  }
}
`;

const shopifyCreateCart = async (_req: NextApiRequest, res: NextApiResponse) => {
  try {
    let response = await shopifyStoreFront(createCartQuery);
    response = response ? cleanGraphQLResponse(response?.cartCreate?.cart) : null;

    res.status(200);
    res.json(response);
  } catch (error) {
    apiError({ errorInText: 'create cart', error, res, resJsonKey: 'userErrors' });
  }
};

export const createShopifyCart = async (): Promise<CreateCartData | undefined> => {
  try {
    const response = await fetch(`${BASE_URL}/api/shopify/cart/create-cart`);
    const resJson = await response?.json();

    return resJson;
  } catch (e: any) {
    console.error('createShopifyCart failed', e);
    if (e) return e;
  }
};

export default shopifyCreateCart;
