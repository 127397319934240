import { NextApiRequest, NextApiResponse } from 'next';

import { CartData } from '../../../../types/store';
import { BASE_URL, apiError } from '../../utils';
import {
  CART_FRAGMENT,
  ShopifyFragmentsNames,
  cleanGraphQLResponse,
  shopifyStoreFront,
} from '../../utils/shopifyApi';

const CartQuery = `
${CART_FRAGMENT}
query getCart($id: ID!) {
  cart(id: $id) {
   ...${ShopifyFragmentsNames.CART_FRAGMENT}
  }
}
`;

const shopifyCart = async (req: NextApiRequest, res: NextApiResponse) => {
  const { id } = req?.query;

  try {
    let response = await shopifyStoreFront(CartQuery, { id });
    response = response ? cleanGraphQLResponse(response?.cart) : null;

    res.status(200);
    res.json(response);
  } catch (error) {
    apiError({ errorInText: 'get cart', error, res, resJsonKey: 'userErrors' });
  }
};

export const getCart = async (id: string): Promise<CartData | undefined> => {
  try {
    const response = await fetch(`${BASE_URL}/api/shopify/cart/cart?id=${id}`);
    const resJson = await response?.json();

    return resJson;
  } catch (e: any) {
    console.error('getCart failed', e);
    if (e) return e;
  }
};

export default shopifyCart;
