import { ElementType, ForwardedRef, forwardRef, memo } from 'react';

import { OverridableComponent, PolymorphicProps, Simplify } from '../../types';
import { Box, SprinklesProps } from './Box';

const defaultElement = 'div';

type CenterVariantsWithSprinkles = Simplify<SprinklesProps>;

type Props = CenterVariantsWithSprinkles & {};

type CenterTypeMap = {
  props: Props;
  defaultComponent: 'div';
};

export type CenterProps<Root extends ElementType = CenterTypeMap['defaultComponent']> =
  PolymorphicProps<CenterTypeMap, Root>;

function CenterImpl(props: CenterProps, ref: ForwardedRef<Element>) {
  const { element, children, ...restProps } = props;
  return (
    <Box
      element={element ?? defaultElement}
      display="flex"
      alignItems="center"
      justifyContent="center"
      ref={ref}
      {...restProps}
    >
      {children}
    </Box>
  );
}

export const Center = memo(forwardRef(CenterImpl)) as OverridableComponent<CenterTypeMap>;
