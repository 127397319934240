import React from 'react';

import { Box, Button, Flex, Text } from '../..';
import { OverridableComponent, Overwrite, PolymorphicProps, Simplify } from '../../types';
import { SprinklesProps } from './Box';
import { FlexProps } from './Flex';
import { TextProps } from './Text';
import { RadioVariants, radioRecipe } from './radio.css';

type RadioVariantsWithSprinkles = Simplify<Overwrite<SprinklesProps, RadioVariants>>;

type Props = RadioVariantsWithSprinkles & {
  disabled?: boolean;
  label: React.ReactNode;
  labelProps?: TextProps<'p'>;
  containerProps?: FlexProps<'div'>;
  isRadioRadio?: boolean;
  hasError?: boolean;
};

type RadioTypeMap = {
  props: Props;
  defaultComponent: 'input';
};

export type RadioProps<Root extends React.ElementType = RadioTypeMap['defaultComponent']> =
  PolymorphicProps<RadioTypeMap, Root>;

function RadioImpl(props: RadioProps, ref: React.ForwardedRef<Element>) {
  const {
    element,
    variant,
    size,
    label,
    labelProps,
    containerProps,
    isRadioRadio,
    checked,
    disabled,
    hasError,
    ...restProps
  } = props;

  const checkboxClassName = radioRecipe({ variant, size, hasError });

  if (isRadioRadio) {
    return (
      <Flex
        element="label"
        align="center"
        gap={isRadioRadio ? '0px' : '12px'}
        style={{ cursor: 'pointer', pointerEvents: disabled ? 'none' : 'auto' }}
        width="fitContent"
        position="relative"
        {...containerProps}
      >
        <Box
          element={element ?? 'input'}
          type="radio"
          className={checkboxClassName}
          position="absolute"
          zIndex="docked"
          disabled={disabled}
          {...restProps}
          checked={checked}
          ref={ref}
          style={{ visibility: 'hidden', pointerEvents: disabled ? 'none' : 'auto' }}
        />
        <Button
          zIndex="base"
          disabled={disabled}
          onClick={(e: any) => {
            e?.preventDefault();
            e.target.value = props?.value;

            props?.onChange?.(e);
          }}
          {...{ variant: checked ? 'primary' : 'gray' }}
          style={{ pointerEvents: disabled ? 'none' : 'auto' }}
          flex="1"
          width="fitContent"
        >
          <Text element="span" size="sm" {...{ color: 'inherit', ...labelProps }} flex="1">
            {label}
          </Text>
        </Button>
      </Flex>
    );
  }

  return (
    <Flex
      element="label"
      align="center"
      gap="12px"
      style={{ cursor: 'pointer' }}
      {...containerProps}
    >
      <Box
        element="input"
        type="radio"
        className={checkboxClassName}
        {...props}
        checked={checked}
        ref={ref}
      />
      <Text element="span" size="sm" {...{ color: 'primary', ...labelProps }} flex="1">
        {label}
      </Text>
    </Flex>
  );
}

export const Radio = React.memo(React.forwardRef(RadioImpl)) as OverridableComponent<RadioTypeMap>;
