import * as React from 'react';

import { Sprinkles } from '../../sprinkles.css';
import { OverridableComponent, PolymorphicProps, Simplify } from '../../types';
import { Box, SprinklesProps } from './Box';

type FlexVariantsWithSprinkles = Simplify<SprinklesProps>;

type Props = FlexVariantsWithSprinkles & {
  direction?: Sprinkles['flexDirection'];
  align?: Sprinkles['alignItems'];
  justify?: Sprinkles['justifyContent'];
};

type FlexTypeMap = {
  props: Props;
  defaultComponent: 'div';
};

export type FlexProps<Root extends React.ElementType = FlexTypeMap['defaultComponent']> =
  PolymorphicProps<FlexTypeMap, Root>;

const defaultElement = 'div';

function FlexImpl(props: FlexProps, ref: React.ForwardedRef<Element>) {
  const {
    direction = 'row',
    align = 'flex-start',
    justify = 'flex-start',
    children,
    ...restProps
  } = props;

  return (
    <Box
      element={defaultElement}
      display="flex"
      flexDirection={direction}
      alignItems={align}
      justifyContent={justify}
      ref={ref}
      {...restProps}
    >
      {children}
    </Box>
  );
}

export const Flex = React.memo(React.forwardRef(FlexImpl)) as OverridableComponent<FlexTypeMap>;
