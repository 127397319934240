import { NextApiRequest, NextApiResponse } from 'next';

import { BASE_URL, apiError } from '../utils';

const addSendInBlueEmailSubscription = async (req: NextApiRequest, res: NextApiResponse) => {
  const { body } = req;

  try {
    const response = await fetch(`https://api.sendinblue.com/v3/contacts`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'api-key': `${process.env.SENDINBLUE_KEY}`,
      },
      body: body,
    });

    const resJSON = await response?.json();

    if (resJSON?.error) {
      throw Error(resJSON?.error);
    }

    res.status(200);
    res.json(await resJSON);
  } catch (error) {
    apiError({
      errorInText: 'add email subscriber',
      error,
      res,
      errorResponseOverRide: 'Add Subscriber Error - Try again later',
    });
  }
};

export interface AddEmailSubscriptionBody {
  email: string;
  listIds?: number[];
}

export interface SendInBlueSubscriber {
  id: string;
}

export const addEmailSubscription = async (body: AddEmailSubscriptionBody) => {
  try {
    const response = await fetch(`${BASE_URL}/api/email-subscription/add-subscriber`, {
      method: 'POST',
      body: JSON.stringify(body),
    });

    const res = await response?.json();

    return res as SendInBlueSubscriber;
  } catch (e: any) {
    console.error('addEmailSubscription failed', e);
    if (e) return e;
  }
};

export default addSendInBlueEmailSubscription;
