import { NextApiRequest, NextApiResponse } from 'next';

import { CartData } from '../../../../types/store';
import { BASE_URL, apiError } from '../../utils';
import {
  CART_FRAGMENT,
  ShopifyFragmentsNames,
  cleanGraphQLResponse,
  shopifyStoreFront,
} from '../../utils/shopifyApi';

const AddToCartQuery = `
${CART_FRAGMENT}
mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
  cartLinesUpdate(cartId: $cartId, lines: $lines) {
    cart {
     ...${ShopifyFragmentsNames.CART_FRAGMENT}
    }
  }
}
`;

const shopifyUpdateCartItem = async (req: NextApiRequest, res: NextApiResponse) => {
  const { body } = req;

  try {
    let response = await shopifyStoreFront(AddToCartQuery, JSON.parse(body));

    response = response ? cleanGraphQLResponse(response?.cartLinesUpdate?.cart) : null;

    res.status(200);
    res.json(response);
  } catch (error) {
    apiError({ errorInText: 'update cart item', error, res, resJsonKey: 'userErrors' });
  }
};

export interface UpdateCartItemBody {
  cartId: string;
  lines: { quantity: number; merchandiseId: string; id: string }[];
}

export const updateCartItem = async (body: UpdateCartItemBody): Promise<CartData | undefined> => {
  try {
    const response = await fetch(`${BASE_URL}/api/shopify/cart/update-cart-item`, {
      method: 'POST',
      body: JSON.stringify(body),
    });

    const resJson = await response?.json();

    return resJson;
  } catch (e: any) {
    console.error('updateCartItem failed', e);
    if (e) return e;
  }
};

export default shopifyUpdateCartItem;
