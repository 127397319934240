import { NextApiRequest, NextApiResponse } from 'next';

import { BASE_URL, apiError } from '../../../pages/api/utils';
import {
  CHECKOUT_ERROR_FRAGMENT,
  CHECKOUT_FRAGMENT,
  ShopifyFragmentsNames,
  shopifyStoreFront,
  transformCheckout,
} from '../../../pages/api/utils/shopifyApi';
import { CheckoutRes } from '../../../types/store';

const createCheckoutQuery = `
${CHECKOUT_FRAGMENT}
${CHECKOUT_ERROR_FRAGMENT}
mutation checkoutCreate($input: CheckoutCreateInput!) {
  checkoutCreate(input: $input) {
    checkout {
      ...${ShopifyFragmentsNames.CHECKOUT_FRAGMENT}
    }
    checkoutUserErrors {
      ...${ShopifyFragmentsNames.CHECKOUT_ERROR_FRAGMENT}
    }  
  }
}
`;

const shopifycreateCheckout = async (req: NextApiRequest, res: NextApiResponse) => {
  const { body } = req;

  try {
    let response = await shopifyStoreFront(createCheckoutQuery, {
      input: {
        allowPartialAddresses: true,
        buyerIdentity: {
          countryCode: 'US',
        },
        shippingAddress: {
          city: 'init-create',
          country: 'US',
          zip: '34609',
          // address1: 'init-create'
        },
        ...JSON.parse(body),
      },
    });

    res.status(200);
    res.json(transformCheckout(response?.checkoutCreate));
  } catch (error) {
    apiError({ errorInText: 'create checkout', error, res, resJsonKey: 'checkoutUserErrors' });
  }
};

export interface CreateCheckoutBodyLineItem {
  customAttributes?: {
    key: string;
    value: string;
  }[];
  quantity: number;
  variantId: string;
}
export interface CreateCheckoutBody {
  lineItems: CreateCheckoutBodyLineItem[];
}

export const createCheckout = async (
  body: CreateCheckoutBody,
): Promise<CheckoutRes | undefined> => {
  try {
    const response = await fetch(`${BASE_URL}/api/shopify/checkout/create-checkout`, {
      method: 'POST',
      body: JSON.stringify(body),
    });

    const resJson = await response?.json();

    return resJson;
  } catch (e) {
    console.error('createCheckout failed', e);
  }
};

export default shopifycreateCheckout;
