//@ts-
import { ElementType, ForwardedRef, ReactNode, forwardRef, memo } from 'react';

import { Box, Flex, Text } from '../..';
import { OverridableComponent, Overwrite, PolymorphicProps, Simplify } from '../../types';
import { SprinklesProps } from './Box';
import { CheckBoxVariants, checkboxRecipe } from './checkbox.css';

type CheckboxVariantsWithSprinkles = Simplify<Overwrite<SprinklesProps, CheckBoxVariants>>;

type Props = CheckboxVariantsWithSprinkles & {
  disabled?: boolean;
  label: ReactNode;
};

type CheckboxTypeMap = {
  props: Props;
  defaultComponent: 'input';
};

export type CheckboxProps<Root extends ElementType = CheckboxTypeMap['defaultComponent']> =
  PolymorphicProps<CheckboxTypeMap, Root>;

function CheckboxImpl(props: CheckboxProps, ref: ForwardedRef<Element>) {
  const { element, variant, size, label, hasError, disabled, ...restProps } = props;

  const checkboxClassName = checkboxRecipe({ variant, size, hasError });

  return (
    <Flex
      element="label"
      align="center"
      color="black"
      textTransform="capitalize"
      gap="12px"
      opacity={disabled ? '0.5' : '1'}
      style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
    >
      <Box
        element={element ?? 'input'}
        type="checkbox"
        tabIndex={0}
        className={checkboxClassName}
        {...restProps}
        ref={ref}
      />
      {typeof label === 'string' ? (
        <Text element="span" variant="base" lineHeight="none" flex="1">
          {label}
        </Text>
      ) : (
        label
      )}
    </Flex>
  );
}

export const Checkbox = memo(forwardRef(CheckboxImpl)) as OverridableComponent<CheckboxTypeMap>;
