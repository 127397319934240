import { OmitFromKnownKeys } from '../../../../global';
import { Box, BoxProps } from '../../index';
import { SpinnerVariants, spinnerRecipe } from './spinner.css';

export type SpinnerProps = SpinnerVariants & OmitFromKnownKeys<BoxProps<'div'>, 'color'>;

export const Spinner = ({ size, color, ...restProps }: SpinnerProps) => {
  const className = spinnerRecipe({ size, color });

  return <Box className={className} {...restProps} />;
};
