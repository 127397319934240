import { NextApiRequest, NextApiResponse } from 'next';

import { BASE_URL, apiError } from '../utils';

const getSendInBlueEmailSubscriptionLists = async (_req: NextApiRequest, res: NextApiResponse) => {
  try {
    const response = await fetch(`https://api.sendinblue.com/v3/contacts/lists`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'api-key': `${process.env.SENDINBLUE_KEY}`,
      },
    });

    const resJSON = await response?.json();

    if (resJSON?.error) {
      throw Error(resJSON?.error);
    }

    res.setHeader('Cache-Control', 's-maxage=86400, stale-while-revalidate=60 ');
    res.status(200);

    res.json(await resJSON);
  } catch (error) {
    apiError({ errorInText: 'SendInBlue lists', error, res });
  }
};

export interface SendInBlueList {
  lists: { id: number; name: string; uniqueSubscribers: number }[];
  count: number;
}

export const getEmailSubscriptionLists = async () => {
  try {
    const response = await fetch(`${BASE_URL}/api/email-subscription/get-subscription-lists`);

    return (await response?.json()) as SendInBlueList;
  } catch (e: any) {
    console.error('getEmailSubscriptionLists failed', e);
  }
};

export default getSendInBlueEmailSubscriptionLists;
