import * as React from 'react';

import { OverridableComponent, Overwrite, PolymorphicProps, Simplify } from '../../types';
import { Box, SprinklesProps } from './Box';
import { InputVariants, iconContainer, inputRecipe } from './input.css';

type InputVariantsWithSprinkles = Simplify<Overwrite<SprinklesProps, InputVariants>>;

type Props = InputVariantsWithSprinkles & {
  icon?: React.ReactNode | null;
  disabled?: boolean;
  hasError?: boolean;
  // autocomplete Ref https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
  autocomplete?:
    | 'off'
    | 'on'
    | 'name'
    | 'given-name'
    | 'family-name'
    | 'email'
    | 'username'
    | 'new-password'
    | 'current-password'
    | 'address-line1'
    | 'address-line2'
    | 'postal-code'
    | 'country-name'
    | 'cc-name'
    | 'cc-number'
    | 'cc-exp'
    | 'cc-csc'
    | 'tel';
};

type InputTypeMap = {
  props: Props;
  defaultComponent: 'input';
};

export type InputProps<Root extends React.ElementType = InputTypeMap['defaultComponent']> =
  PolymorphicProps<InputTypeMap, Root>;

function InputImpl(props: InputProps, ref: React.ForwardedRef<Element>) {
  const {
    element,
    variant,
    size,
    colorScheme,
    width = 'full',
    focusVisible = false,
    hasError,
    children,
    disabled = false,
    icon = null,
    className,
    id,
    name,
    autocomplete,
    ...restProps
  } = props;

  const inputClassName = inputRecipe({
    variant,
    colorScheme,
    size,
    focusVisible,
    error: hasError,
    disabled,
  });

  return (
    <Box element="span" position="relative" width={width} flex="1">
      <Box
        element={element ?? 'input'}
        display="block"
        fontFamily="body"
        id={id ?? name}
        name={name}
        autoComplete={autocomplete}
        disabled={disabled}
        className={[inputClassName, className].join(' ')}
        {...restProps}
        ref={ref}
        paddingRight={restProps.type === 'password' ? '44px' : undefined}
        {...(icon && { style: { ...restProps?.style, paddingLeft: '44px !important' } })}
      />
      {icon && <Box className={iconContainer}>{icon}</Box>}
    </Box>
  );
}

export const Input = React.memo(React.forwardRef(InputImpl)) as OverridableComponent<InputTypeMap>;
