import { NextApiRequest, NextApiResponse } from 'next';

import { ProductData } from '../../../../types/store';
import { BASE_URL, apiError } from '../../utils';
import {
  PRODUCT_FRAGMENT,
  ShopifyFragmentsNames,
  cleanGraphQLResponse,
  shopifyStoreFront,
  transformProduct,
} from '../../utils/shopifyApi';

const productRecommendationsQuery = `
${PRODUCT_FRAGMENT}
query ProductRecommendations($id: ID!) {
  productRecommendations(productId: $id) {
    ...${ShopifyFragmentsNames.PRODUCT_FRAGMENT}
  }
}
`;

const shopifyProductRecommendations = async (req: NextApiRequest, res: NextApiResponse) => {
  try {
    const { productId } = req?.query;

    let response = await shopifyStoreFront(productRecommendationsQuery, { id: productId });
    response = response
      ? response?.productRecommendations?.map((product: ProductData) =>
          cleanGraphQLResponse(product),
        )
      : null;

    res.status(200);
    res.json(response);
  } catch (error) {
    apiError({
      errorInText: 'product recommendations',
      error,
      res,
    });
  }
};

export const getShopifyProductRecommendations = async (productId: string) => {
  try {
    const response = await fetch(
      `${BASE_URL}/api/shopify/products/product-recommendations?productId=${productId}`,
    );

    const resJson = await response?.json();
    const jsonValueArray = resJson && Object.values(resJson);

    if (Array.isArray(jsonValueArray)) {
      const transformedAllRecommendations = (jsonValueArray as ProductData[] | undefined)?.map(
        (recommendation: ProductData) => transformProduct(recommendation),
      );

      return jsonValueArray ? transformedAllRecommendations : null;
    }

    return null;
  } catch (e: any) {
    console.error('getShopifyProductRecommendations failed', e);
    if (e) return e;
  }
};

export default shopifyProductRecommendations;
