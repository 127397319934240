import { NextApiRequest, NextApiResponse } from 'next';

import { CartData } from '../../../../types/store';
import { BASE_URL, apiError } from '../../utils';
import {
  CART_FRAGMENT,
  ShopifyFragmentsNames,
  cleanGraphQLResponse,
  shopifyStoreFront,
} from '../../utils/shopifyApi';

const AddToCartQuery = `
${CART_FRAGMENT}
mutation addToCart($cartId: ID!, $lines: [CartLineInput!]!) {
  cartLinesAdd(cartId: $cartId, lines: $lines) {
    cart {
      ...${ShopifyFragmentsNames.CART_FRAGMENT}
    }
  }
}
`;

const shopifyAddToCart = async (req: NextApiRequest, res: NextApiResponse) => {
  const { body } = req;

  try {
    let response = await shopifyStoreFront(AddToCartQuery, JSON.parse(body));
    response = response ? cleanGraphQLResponse(response?.cartLinesAdd?.cart) : null;

    res.status(200);
    res.json(response);
  } catch (error) {
    apiError({ errorInText: 'add to cart', error, res, resJsonKey: 'userErrors' });
  }
};

export interface AddToCartBody {
  cartId: string;
  lines: { quantity: number; merchandiseId: string }[];
}

export const addToCart = async (body: AddToCartBody): Promise<CartData | undefined> => {
  try {
    const response = await fetch(`${BASE_URL}/api/shopify/cart/add-to-cart`, {
      method: 'POST',
      body: JSON.stringify(body),
    });

    const resJson = await response?.json();

    return resJson;
  } catch (e: any) {
    console.error('addToCart failed', e);
    if (e) return e;
  }
};

export default shopifyAddToCart;
