import { splitStringOnForwardSlash } from '.';
import { UnknownDict } from '../global';

export const companyName = 'Aeribella';

export const apiErrorMessage = (wrongWhenText: string) =>
  `Something went wrong when ${wrongWhenText}. Please refresh and try again or check back later and try again. We have been notified of this issue.`;

export enum Routes {
  home = '/',
  cart = '/cart',
  shop = '/shop',
  faq = '/faq',
  contact = '/contact',
  instagram = 'https://www.instagram.com/aeribella_plants/',
  checkout = '/checkout',
  privacyPolicy = '/legal/privacy-policy',
  termsOfService = '/legal/terms-of-service',
  ShippingAndReturnPolicy = '/legal/shipping-return-policy',
  about = '/about',
}

export const specialRouteHrefs = {
  pdp: ({
    handle,
    productExistsInStore,
    variantID,
  }: {
    handle: string;
    productExistsInStore?: boolean;
    variantID?: string;
  }) => ({
    pathname: `${Routes.shop}/${handle}`,
    query: { productExistsInStore, variantID },
  }),
  cart: ({ id }: { id?: string }) => ({
    pathname: Routes.cart,
    query: { id: id && encodeURIComponent(splitStringOnForwardSlash(id)) },
  }),
  checkout: ({ id }: { id?: string }) => {
    return {
      pathname: Routes.checkout,
      query: { id: id && encodeURIComponent(splitStringOnForwardSlash(id)) },
    };
  },
  plp: ({
    searchQuery,
    categoryType,
  }: {
    searchQuery?: string;
    categoryType?: KnownProductTypes;
  }) => {
    return {
      pathname: Routes.shop,
      query: { searchQuery, categoryType },
    };
  },
};

export enum KnownSubscriptionListNames {
  aeribellaEmailSubscriber = 'Subscribers',
}

export enum KnownProductTypes {
  orchid = 'product_type:orchid',
  all = 'all',
}

export const KnownTags = {
  // Size
  nearBloomSize: 'tag:"near bloom"',
  inSpike: 'tag:"in spike"',
  bloomSize: 'tag:"bloom size"',
  young: 'tag:"young"',

  // Orchid Genera
  cattleya: 'tag:"cattleya"',
  brassavola: 'tag:"brassavola"',
  dendrobium: 'tag:"dendrobium"',
  encyclia: 'tag:"Encyclia"',
  miltoniopsis: 'tag:"Miltoniopsis"',
  miscGenera: 'tag:"Misc Genera"',
  bulbophyllum: 'tag:"bulbophyllum"',
  vanda: 'tag:"Vanda"',
  oncidiumAndIntergenerics: 'tag:"oncidium & intergenerics"',
};

export const defaultCategoryOptionsValues = KnownProductTypes.orchid;

export const onlineOnlyMessage =
  'We are an online business only, our physical location is not open to the public.';

export const categoryOptions = [
  { name: 'all', value: KnownProductTypes.all },
  { name: 'orchids', value: KnownProductTypes.orchid },
] as const;

export const categoryOptionsValues = categoryOptions.map(({ value }) => value);

export type CategoryOptionsValues = (typeof categoryOptionsValues)[number];

export interface FilterOptions {
  [key: string]: {
    title: string;
    description?: string;
    options: {
      label: string;
      value: string;
    }[];
  }[];
}

export const filterOptions: FilterOptions = {
  [KnownProductTypes.all]: [
    {
      title: 'category',
      description: 'Get more filters when selecting a category',
      options: [{ label: 'orchids', value: `${KnownProductTypes.orchid}` }],
    },
  ],
  [KnownProductTypes.orchid]: [
    {
      title: 'genera',
      options: [
        {
          label: 'cattleya',
          value: KnownTags.cattleya,
        },
        {
          label: 'brassavola',
          value: KnownTags.brassavola,
        },
        {
          label: 'bulbophyllum',
          value: KnownTags.bulbophyllum,
        },
        {
          label: 'dendrobium',
          value: KnownTags.dendrobium,
        },
        {
          label: 'encyclia',
          value: KnownTags.encyclia,
        },
        {
          label: 'miltoniopsis',
          value: KnownTags.miltoniopsis,
        },
        {
          label: 'Vanda',
          value: KnownTags.vanda,
        },
        {
          label: 'oncidium & intergenerics',
          value: KnownTags.oncidiumAndIntergenerics,
        },
        {
          label: 'misc genera',
          value: KnownTags.miscGenera,
        },
      ],
    },
    {
      title: 'size',
      options: [
        {
          label: 'bloom size',
          value: KnownTags.bloomSize,
        },
        {
          label: 'in spike / bud',
          value: KnownTags.inSpike,
        },
        {
          label: 'near bloom size',
          value: KnownTags.nearBloomSize,
        },
        {
          label: 'young',
          value: KnownTags.young,
        },
      ],
    },
  ],
};

export interface NavItem {
  name: string;
  location:
    | string
    | {
        pathname: Routes;
        query: UnknownDict;
      };
}

export interface NavItems extends NavItem {
  dropdown?: { catName: string; routes: NavItem[] | string }[];
}

export const navigationItems: NavItems[] = [
  { name: 'Home', location: Routes.home },
  {
    name: 'Shop',
    location: Routes.shop,
    dropdown: [
      {
        catName: 'Orchids',
        routes: [
          {
            name: 'All Orchids',
            location: specialRouteHrefs.plp({
              searchQuery: '',
              categoryType: KnownProductTypes.orchid,
            }),
          },
          {
            name: 'Cattleya',
            location: specialRouteHrefs.plp({
              searchQuery: KnownTags.cattleya,
              categoryType: KnownProductTypes.orchid,
            }),
          },
          {
            name: 'oncidium & intergenerics',
            location: specialRouteHrefs.plp({
              searchQuery: KnownTags.oncidiumAndIntergenerics,
              categoryType: KnownProductTypes.orchid,
            }),
          },
          {
            name: 'Bloom Size',
            location: specialRouteHrefs.plp({
              searchQuery: KnownTags.bloomSize,

              categoryType: KnownProductTypes.orchid,
            }),
          },
          {
            name: 'In Spike',
            location: specialRouteHrefs.plp({
              searchQuery: KnownTags.inSpike,
              categoryType: KnownProductTypes.orchid,
            }),
          },
          {
            name: 'Near Bloom Size',
            location: specialRouteHrefs.plp({
              searchQuery: KnownTags.nearBloomSize,
              categoryType: KnownProductTypes.orchid,
            }),
          },
        ],
      },
      {
        catName: 'Gift Cards',
        routes: '/shop/aeribella-gift-card',
      },
    ],
  },
  { name: 'FAQ', location: Routes.faq },
  { name: 'About Us', location: Routes.about },
  { name: 'Contact', location: Routes.contact },
];

export const stateSelectList = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'DC', label: 'District of Columbia' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
];

export enum CARD_TYPES {
  AMERICAN_EXPRESS = 'amex',
  VISA = 'visa',
  DISCOVER = 'discover',
  MASTERCARD = 'master-card',
}

export type CARD_TYPES_CODE = `${CARD_TYPES}`;

export const CARD_TYPES_NAMES = {
  [CARD_TYPES.AMERICAN_EXPRESS]: 'Amex',
  [CARD_TYPES.VISA]: 'Visa',
  [CARD_TYPES.DISCOVER]: 'Discover',
  [CARD_TYPES.MASTERCARD]: 'Master Card',
} as const;

export const CreditCardValidatorTypesMap = {
  mastercard: CARD_TYPES.MASTERCARD,
  amex: CARD_TYPES.AMERICAN_EXPRESS,
  visa: CARD_TYPES.VISA,
  discover: CARD_TYPES.DISCOVER,
};

export interface formatCreditCardNumberOpts {
  gaps: number[] | never[];
  lengths: number[] | never[];
}

// export const stripeSecret =
//   process.env.NODE_ENV === 'production' ? process.env.STRIPE_SECRET_PROD : process.env.STRIPE_SECRET_DEV;
// export const stripePublishableKey = null;
// export const stripePublishableKey =
//   process.env.NODE_ENV === 'production'
//     ? process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY_PROD
//     : process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY_DEV;
