import React, { ComponentProps, useEffect, useRef } from 'react';

import { scrollToTargetAdjusted } from '../../../../utils';
import { CheckCircleIcon, ErrorCircleIcon, InfoIcon, WarningIcon } from '../../Icons';
import { Box } from './Box';
import { Flex } from './Flex';
import { Stack } from './Stack';
import { alertRecipe } from './alert.css';

export interface Alert extends ComponentProps<typeof Flex> {
  status: 'error' | 'warning' | 'info' | 'success';
  autoScroll?: boolean;
  children?: React.ReactNode;
  iconSize?: string;
  iconOnly?: boolean;
}

export const Alert = ({
  status,
  children,
  autoScroll,
  iconSize,
  iconOnly,
  ...restProps
}: Alert) => {
  const alertMessage = useRef<HTMLDivElement | null>(null);
  const className = alertRecipe({ status, iconOnly });
  const iconSizeSelector = iconSize ?? (iconOnly ? '24px' : '32px');
  const statusOptions = {
    error: <ErrorCircleIcon color="inherit" size={iconSizeSelector} />,
    warning: <WarningIcon color="inherit" size={iconSizeSelector} />,
    info: <InfoIcon color="inherit" size={iconSizeSelector} />,
    success: <CheckCircleIcon color="inherit" size={iconSizeSelector} />,
  };

  useEffect(() => {
    let mounted = true;
    if (!mounted || !autoScroll) return;

    if (alertMessage?.current) {
      scrollToTargetAdjusted(alertMessage?.current, 150);
    }
    return () => {
      mounted = false;
    };
  }, [alertMessage?.current]);

  return (
    <Stack
      ref={alertMessage}
      direction="row"
      className={className}
      gap="8px"
      align="center"
      {...restProps}
    >
      {statusOptions[status] && <Box>{statusOptions[status]}</Box>}
      {children && <Box color="black">{children}</Box>}
    </Stack>
  );
};
