import { ElementType, ForwardedRef, forwardRef, memo } from 'react';

import { OverridableComponent, Overwrite, PolymorphicProps, Simplify } from '../../types';
import { Box, SprinklesProps } from './Box';
import { Text, TextProps } from './Text';
import { ButtonVariants, buttonInvariant, buttonRecipe } from './button.css';
import { Spinner, SpinnerProps } from './spinner';

type ButtonVariantsWithSprinkles = Simplify<Overwrite<SprinklesProps, ButtonVariants>>;

type Props = Omit<ButtonVariantsWithSprinkles, 'padding' | 'active'> & {
  disabled?: boolean;
  size?: ButtonVariantsWithSprinkles['size'];
  variant?: ButtonVariantsWithSprinkles['variant'];
  isLoading?: boolean;
  overrideDefaultLoader?: boolean;
  textProps?: Omit<TextProps<'span'>, 'ref'>;
  loaderSize?: SpinnerProps['size'];
  spinnerColor?: SpinnerProps['color'];
  form?: string;
};

type ButtonTypeMap = {
  props: Props;
  defaultComponent: 'button';
};

export type ButtonProps<Root extends ElementType = ButtonTypeMap['defaultComponent']> =
  PolymorphicProps<ButtonTypeMap, Root>;

const defaultElement = 'button';

function ButtonImpl(props: ButtonProps, ref: ForwardedRef<Element>) {
  const {
    element,
    size,
    type,
    variant,
    disabled,
    isLoading,
    children,
    style,
    display,
    textProps,
    fontSize = 'md',
    loaderSize,
    textTransform = 'capitalize',
    form,
    spinnerColor = 'white',
    className,
    overrideDefaultLoader = false,
    ...restProps
  } = props;

  // Use `variant='unstyled'` to bypass recipe. This allows for full customization
  // via style props. Note that the `unstyled` variant is not part of the recipe and
  // is only used here as a flag to bypass the recipe.
  const noVariant = variant === 'unstyled';
  const isDisabled =
    ['button', 'fieldset', 'optgroup', 'option', 'select', 'textarea', 'input'].includes(
      typeof element === 'string' ? element : defaultElement,
    ) &&
    (!!isLoading || !!disabled);
  const buttonClassName = noVariant ? buttonInvariant : buttonRecipe({ size, variant });

  return (
    <Box
      className={[buttonClassName, className].join(' ')}
      element={element ?? defaultElement}
      type={type ?? (element === 'button' ? 'button' : undefined)}
      display={display ?? 'inline-flex'}
      position="relative"
      alignItems="center"
      justifyContent="center"
      height="44px"
      paddingX="12px"
      bg="transparent"
      border="none"
      fontSize={fontSize}
      textTransform={textTransform}
      style={{ ...style, opacity: isDisabled ? '0.5' : '1' }}
      {...restProps}
      {...(isDisabled ? { disabled: isDisabled } : {})}
      ref={ref}
    >
      {isLoading && !overrideDefaultLoader ? (
        <Spinner size={loaderSize} color={spinnerColor} />
      ) : typeof children === 'string' ? (
        <Text element="span" color="inherit" fontSize="inherit" fontWeight="inherit" {...textProps}>
          {children}
        </Text>
      ) : (
        children
      )}
    </Box>
  );
}

export const Button = memo(forwardRef(ButtonImpl)) as OverridableComponent<ButtonTypeMap>;
