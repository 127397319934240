import { CheckBoxFieldSetProps } from '../../../components/Forms/CheckBoxFieldSet';
import { UnknownDict } from '../../../global';
import {
  CheckoutData,
  CheckoutRes,
  CollectionData,
  HydrationData,
  ProductData,
  SearchTags,
} from '../../../types/store';
import { capitalize } from '../../../utils';
import { KnownProductTypes } from '../../../utils/const';
import { ShopifyProductsSearchBody } from '../shopify/products/products-search';

export const cleanGraphQLResponse = function (input: UnknownDict) {
  if (!input) return null;

  let output: UnknownDict = {};
  const isObject = (obj: UnknownDict) => {
    return obj !== null && typeof obj === 'object' && !Array.isArray(obj);
  };

  const isPrimitiveType = (test: UnknownDict) => {
    return test !== Object(test);
  };

  if (isPrimitiveType(input)) return input;

  Object.keys(input).forEach(key => {
    if (input[key] && input[key].edges) {
      output[key] = input[key].edges.map((edge: UnknownDict) => cleanGraphQLResponse(edge.node));
    } else if (input[key] && input[key]?.nodes) {
      output[key] = input[key]?.nodes;
    } else if (isObject(input[key])) {
      output[key] = cleanGraphQLResponse(input[key]);
    } else if (key !== '__typename') {
      output[key] = input[key];
    }
  });

  return output;
};

export const shopifyStoreFront = async (
  query: string,
  variables: UnknownDict = {},
  disableCleaner?: boolean,
) => {
  const res = await fetch(
    `https://${process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN}/api/2023-04/graphql.json`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_TOKEN ?? '',
      },
      body: JSON.stringify({ query, variables }),
    },
  );

  if (res) {
    const resJson = await res.json();

    if (resJson.errors) {
      console.error('\x1b[31m%s\x1b[0m', 'Shopify StoreFront Error');

      const queryOrMutation = query?.includes('mutation')
        ? `m${query
            .substring(query.indexOf('mutation') + 1)
            ?.slice(0, 100)
            .trim()}...`
        : `q${query
            .substring(query.indexOf('query') + 1)
            ?.slice(0, 100)
            .trim()}...`;

      console.error('\x1b[31m%s\x1b[0m', JSON.stringify(resJson.errors));
      console.error('');
      console.error('\x1b[31m%s\x1b[0m', JSON.stringify(queryOrMutation));
      throw resJson.errors;
    }

    return disableCleaner ? resJson.data : cleanGraphQLResponse(resJson.data);
  }
  return null;
};

export const extractShopifyIdNumber = (id?: string | null) =>
  id?.split('/')[id?.split('/').length - 1];

export const transformPrice = (price: string | null, comparePrice?: string | null) => {
  const newPrice =
    comparePrice && comparePrice !== '0.0'
      ? {
          base: comparePrice,
          discounted: price,
        }
      : {
          base: price,
          discounted: null,
        };

  return newPrice;
};

export const transformProduct = (product: ProductData): ProductData => {
  const price = transformPrice(
    product?.priceRange?.minVariantPrice?.amount ?? product?.priceRange?.amount ?? null,
    product?.compareAtPriceRange?.minVariantPrice?.amount ??
      product?.compareAtPriceRange?.amount ??
      null,
  );

  const min = product?.priceRange?.minVariantPrice?.amount;
  const max = product?.priceRange?.maxVariantPrice?.amount;

  const priceRangeDisplay =
    product?.priceRange?.minVariantPrice?.amount !== product?.priceRange?.maxVariantPrice?.amount
      ? `$${min && !isNaN(+min) ? (+min).toFixed(2) : min} - $${max && !isNaN(+max) ? (+max).toFixed(2) : max}`
      : null;

  return {
    ...product,
    idNumber: extractShopifyIdNumber(product?.id) ?? null,
    price: price,
    priceRangeDisplay,
    variants: product?.variants?.map(({ compareAtPrice, price, ...all }) => ({
      ...all,
      varPrice: transformPrice(price?.amount ?? null, compareAtPrice?.amount ?? null),
    })),
  };
};

export const transformMultiProducts = (products: ProductData[]): ProductData[] => {
  return products?.map((product: ProductData) => transformProduct(product));
};

export const transformCollection = (collection: CollectionData): CollectionData => {
  const products = transformMultiProducts(collection?.products);

  return {
    ...collection,
    idNumber: extractShopifyIdNumber(collection?.id ?? undefined) ?? undefined,
    products,
  };
};

export const hydrateServerSideHandler = (hydrationObj?: HydrationData) => {
  return {
    hydrationData: {
      ...hydrationObj,
    },
  };
};

export const transformSearchTags = (searchTagsArray: string[] | null): SearchTags | null => {
  const filterTitles = {
    c: 'category',
    s: 'size',
  };
  type filterTitleKeys = keyof typeof filterTitles;

  const edges = searchTagsArray
    ?.map(tag => {
      if (!tag.includes(':')) return null;
      const tagSplit = tag.split(':');
      const title = (tagSplit?.[0] as filterTitleKeys)
        ? filterTitles[tagSplit?.[0] as filterTitleKeys]
        : null;
      const filterLabel = tagSplit?.[1]?.trimStart()?.trim();

      if (title && filterLabel) {
        return { title, label: filterLabel, value: 'tag:' + `'${tag?.replace(/\s/g, '-')}'` };
      }
    })
    ?.filter(Boolean);

  if (!edges) {
    return null;
  }

  const searchTagsObject = edges?.reduce(
    (group: Record<string, CheckBoxFieldSetProps['options']> | null, tagInfo) => {
      if (!tagInfo || !group) return null;
      const { title, value, label } = tagInfo;

      group[title] = group[title] ?? [];
      group[title]?.push({ label, value });

      return group;
    },
    {},
  );

  const searchTags = searchTagsObject
    ? Object.keys(searchTagsObject)?.map(key => ({ title: key, options: searchTagsObject[key] }))
    : null;

  return searchTags;
};

export const transformCheckout = (checkoutRes: CheckoutRes): CheckoutRes => {
  if (!checkoutRes?.checkout) return checkoutRes;

  const { shippingAddress, lineItems, subtotalPrice, totalTax, totalPrice, ...restCheckout } =
    checkoutRes.checkout;

  const newlineItems: CheckoutData['lineItems'] = lineItems.map(({ variant, ...rest }) => {
    const { compareAtPrice, price, ...variantRest } = variant;
    const customPrice = transformPrice(price?.amount ?? null, compareAtPrice?.amount ?? null);

    return {
      ...rest,
      variant: { ...variantRest, customPrice },
    };
  });

  const setShippingAddressToNull = shippingAddress?.formattedArea.includes('init-create');
  const shippingPrice = restCheckout?.shippingLine?.price ?? null;

  return {
    checkout: checkoutRes?.checkout
      ? {
          ...restCheckout,
          lineItems: newlineItems,
          shippingAddress: setShippingAddressToNull ? null : shippingAddress,
          subtotalPrice,
          totalPrice,
          totalTax,
          cost: {
            subtotalAmount: subtotalPrice,
            totalTaxAmount: totalTax,
            totalAmount: totalPrice,
            shippingAmount: shippingPrice,
          },
        }
      : null,
    checkoutUserErrors:
      checkoutRes?.checkoutUserErrors?.length !== 0 ? checkoutRes?.checkoutUserErrors : null,
  };
};

export const specialProductIds = {
  heatPack: '7725098336501',
};

export const shopifyTagClean = (tags?: string[]) => {
  if (!tags) return;

  return tags
    ?.map((tag: string) =>
      capitalize(tag.split(':')?.[tag.split(':').length - 1]?.replace(/['"-]/g, ' ')),
    )
    .filter((string): string is string => {
      return string !== null || string !== undefined;
    });
};

export const predefinedProductSearch: Record<'newOrchidArrivals', ShopifyProductsSearchBody> = {
  newOrchidArrivals: {
    query: KnownProductTypes.orchid,
    first: 8,
    sortBy: 'UPDATED_AT',
    disableTotalCounts: true,
  },
};

// Fragments
export enum ShopifyFragmentsNames {
  PRODUCT_FRAGMENT = 'productFragment',
  CART_FRAGMENT = 'cartFragment',
  CHECKOUT_FRAGMENT = 'checkoutFragment',
  CHECKOUT_ERROR_FRAGMENT = 'checkoutErrorFragment',
}

export const PRODUCT_FRAGMENT = `
fragment ${ShopifyFragmentsNames.PRODUCT_FRAGMENT} on Product {
  id
  title
  handle
  productType
  createdAt
  updatedAt
  totalInventory
  tags
  availableForSale
  compareAtPriceRange {
    minVariantPrice {
      amount
    }
  }
  priceRange {
    minVariantPrice {
      amount
    }
    maxVariantPrice {
      amount
    }
  }
  seo {
    description
    title
  }
  featuredImage {
    url(transform: {preferredContentType: WEBP})
    altText
    id
  }
  images(first: 5) {
    edges {
      node {
        altText
        url(transform: {preferredContentType: WEBP})
        id
      }
    }
  }
  variants(first: 11) {
    edges {
      node {
        id
        sku
        title
        quantityAvailable
        compareAtPrice {
          amount
        }
        price {
          amount
        }
      }
    }
  }
  description
  descriptionHtml
}
`;

export const CART_FRAGMENT = `
fragment ${ShopifyFragmentsNames.CART_FRAGMENT} on Cart {
  id
  checkoutUrl
  cost {
    subtotalAmount {
      amount
    }
    totalTaxAmount {
      amount
    }
    totalAmount {
      amount
    }
  }
  lines(first: 250) {
    nodes {
      quantity
      id

      cost {
        compareAtAmountPerQuantity{
          amount
        }
        subtotalAmount {
          amount
        }
        totalAmount {
          amount
        }
      }
      
      merchandise {
        ... on ProductVariant {
          id
          sku
          quantityAvailable
          image {
            url(transform: {preferredContentType: WEBP})
            altText
          }
          title
          product {
            id
            title
            productType
            availableForSale
            tags
            handle
            priceRange {
              minVariantPrice {
                amount
              }
            }
            compareAtPriceRange {
              minVariantPrice {
                amount
              }
            }
          }
        }
      }
      discountAllocations {
        ... on CartCodeDiscountAllocation {
          code
          discountedAmount {
            amount
          }
        }
        ... on CartAutomaticDiscountAllocation {
          title
          discountedAmount {
            amount
          }
        }
      }
    }
  }
  updatedAt
  discountAllocations {
    ... on CartCodeDiscountAllocation {
      code
      discountedAmount {
        amount
      }
    }
    ... on CartAutomaticDiscountAllocation {
      title
      discountedAmount {
        amount
      }
    }
  }
  discountCodes {
    code
    applicable
  }
}
`;

const CHECKOUT_AUTO_DISCO_APP = `
fragment automaticDiscountApplication on AutomaticDiscountApplication {
  ... on AutomaticDiscountApplication {
    title
    value {
      ... on MoneyV2 {
        amount
      }
      ... on PricingPercentageValue {
        percentage
      }
    }
    targetType
    targetSelection
    allocationMethod
  }
}
`;

const CHECKOUT_MANUAL_DISCO_APP = `
fragment manualDiscountApplication on ManualDiscountApplication {
  ... on ManualDiscountApplication {
    title
    value {
      ... on MoneyV2 {
        amount
      }
      ... on PricingPercentageValue {
        percentage
      }
    }
    targetType
    targetSelection
    allocationMethod
  }
}
`;

export const CHECKOUT_FRAGMENT = `
${CHECKOUT_AUTO_DISCO_APP}
${CHECKOUT_MANUAL_DISCO_APP}
fragment checkoutFragment on Checkout {
  id
  email
  createdAt
  completedAt
  currencyCode
  taxExempt
  taxesIncluded
  orderStatusUrl
  ready
  requiresShipping
  webUrl
  note
  totalTax {
    amount
  }
  paymentDue {
    amount
  }
  totalPrice {
    amount
  }
  subtotalPrice {
    amount
  }
  lineItemsSubtotalPrice {
    amount
  }
  shippingLine {
    handle
    price {
      amount
    }
    title
  }
  shippingAddress {
    firstName
    lastName
    address1
    address2
    company
    formattedArea
    formatted
    phone
    zip
    city
    province
  }
  order {
    name
    email
    totalTax {
      amount
    }
    statusUrl
  }
  buyerIdentity {
    countryCode
  }
  appliedGiftCards {
    id
    balance {
      amount
    }
    amountUsed {
      amount
    }
    lastCharacters
    presentmentAmountUsed {
      amount
    }
  }
  availableShippingRates {
    ready
    shippingRates {
      price {
        amount
      }
      title
      handle
    }
  }
  shippingDiscountAllocations {
    allocatedAmount {
      amount
    }
    discountApplication {
      value {
        __typename
      }
      targetType
      allocationMethod
    }
  }
  discountApplications(first: 250) {
    edges {
      node {
        ...automaticDiscountApplication
        ...manualDiscountApplication
      }
    }
  }
  lineItems(first: 250) {
    edges {
      node {
        id
        title
        quantity
        variant {
          id
          image {
            url(transform: {preferredContentType: WEBP})
            altText
          }
          compareAtPrice {
            amount
          }
          price {
            amount
          }
          availableForSale
          quantityAvailable
        }
        discountAllocations {
          allocatedAmount {
            amount
          }
          discountApplication {
            ...automaticDiscountApplication
            ...manualDiscountApplication
          }
        }
      }
    }
  }
}
`;

export const CHECKOUT_ERROR_FRAGMENT = `
fragment ${ShopifyFragmentsNames.CHECKOUT_ERROR_FRAGMENT} on CheckoutUserError {
  field
  code
  message
}
`;
