import {
  ElementRef,
  ElementType,
  ForwardedRef,
  ReactElement,
  ReactNode,
  RefAttributes,
  forwardRef,
  memo,
} from 'react';

import { CSSProperties } from '@vanilla-extract/css';

import { Sprinkles, sprinkles } from '../../sprinkles.css';
import { OverridableComponent, PolymorphicProps } from '../../types/components';

/**
 * Combine the Box-specific props and the component props for a given element type
 * @deprecated use `PolymorphicProps` and `OverridableComponent`
 */
export type PolymorphicComponentProps<E extends ElementType, P> = P & BoxProps<E>;

/**
 * Define a polymorphic component that can render any element type
 * @deprecated use `PolymorphicProps` and `OverridableComponent`
 */
export type PolymorphicComponent<P, D extends ElementType = 'div'> = <E extends ElementType = D>(
  props: PolymorphicComponentProps<E, P> & RefAttributes<ElementRef<E>>, // Use ElementRef<E>
) => ReactElement | null;

/**
 * Define the props for our Box component, which can be used to render any HTML element
 * @deprecated use `BoxProps` or `SprinklesProps`
 */
export type BoxSpecificProps<E extends ElementType = ElementType> = {
  element?: E | ElementType;
  className?: string;
  children?: ReactNode | ReactNode[];
  style?: CSSProperties;
} & Sprinkles;

export type SprinklesProps = Sprinkles;

type BoxTypeMap = {
  props: SprinklesProps;
  // The default element type to render if no "element" prop is specified
  defaultComponent: 'div';
};

export type BoxProps<Root extends ElementType = BoxTypeMap['defaultComponent']> = PolymorphicProps<
  BoxTypeMap,
  Root
>;

/**
 * Combines an array of class names into a single string, filtering out empty strings and null/undefined values
 */
function composeClassNames(...classNames: Array<string | undefined>) {
  const classes = classNames
    .filter(className => Boolean(className) && className !== ' ')
    .map(className => className?.toString().trim());
  return classes.length === 0 ? undefined : classes.join(' ');
}

/**
 * Separates style props from element props and returns an object for each.
 */
function extractSprinklesFromProps(props: any) {
  let hasStyleProps = false;
  let styleProps: { [key: string]: any } = {};
  let elementProps: { [key: string]: any } = {};

  for (const key in props) {
    if (sprinkles.properties.has(key as any)) {
      hasStyleProps = true;
      styleProps[key] = props[key];
    } else {
      elementProps[key] = props[key];
    }
  }

  return { hasStyleProps, styleProps, elementProps };
}

function BoxImpl(props: BoxProps, forwardedRef: ForwardedRef<Element>) {
  const { element, className, style, children, ...otherProps } = props;

  const Element = element ?? 'div';
  const { hasStyleProps, styleProps, elementProps } = extractSprinklesFromProps(otherProps);
  const classNames = hasStyleProps
    ? composeClassNames(sprinkles(styleProps), className)
    : className;

  return (
    <Element className={classNames} style={style} {...elementProps} ref={forwardedRef}>
      {children}
    </Element>
  );
}

/**
 * Box component, which accepts any HTML element type and applies style props as class names
 */
export const Box = memo(forwardRef(BoxImpl)) as OverridableComponent<BoxTypeMap>;
