import { NextRequest } from 'next/server';

import { ProductData, ProductSearchData } from '../../../../types/store';
import { CategoryOptionsValues } from '../../../../utils/const';
import { BASE_URL, apiError } from '../../utils';
import {
  PRODUCT_FRAGMENT,
  ShopifyFragmentsNames,
  cleanGraphQLResponse,
  shopifyStoreFront,
  transformMultiProducts,
} from '../../utils/shopifyApi';

export const config = {
  runtime: 'edge',
};

const productsSearchQuery = `
${PRODUCT_FRAGMENT}
query ProductsSearch($query: String!, $first: Int, $last: Int, $before: String, $after: String, $sortBy: ProductSortKeys = UPDATED_AT, $reverse: Boolean = false) {
  products(query: $query, first: $first, last: $last, before: $before, after: $after, sortKey: $sortBy, reverse: $reverse) {
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        ...${ShopifyFragmentsNames.PRODUCT_FRAGMENT}
      }
    }
  }
}
`;

const getTotalProductsSearchQuery = `
query ProductsSearch($query: String!, $first: Int, $last: Int, $before: String, $after: String, $sortBy: ProductSortKeys = UPDATED_AT, $reverse: Boolean = false) {
  products(query: $query, first: $first, last: $last, before: $before, after: $after, sortKey: $sortBy, reverse: $reverse) {
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      node {
        id
        handle
      }
    }
  }
}
`;

export const sortByDefaults: Record<
  string,
  Pick<ShopifyProductsSearchBody, 'reverse' | 'sortBy'>
> = {
  'New Arrivals': { sortBy: 'UPDATED_AT', reverse: true },
  'Best Selling': { sortBy: 'BEST_SELLING', reverse: false },
  'Price: Low to High': { sortBy: 'PRICE', reverse: false },
  'Price: High to Low': { sortBy: 'PRICE', reverse: true },
  'Most Relevant': { sortBy: 'RELEVANCE', reverse: false },
};

export const sortByOptions = Object.keys(sortByDefaults)?.map(optionKey => ({
  label: optionKey,
  value: optionKey,
}));

export type ProductsSortBy =
  | 'TITLE'
  | 'PRODUCT_TYPE'
  | 'VENDOR'
  | 'UPDATED_AT'
  | 'CREATED_AT'
  | 'BEST_SELLING'
  | 'PRICE'
  | 'RELEVANCE'
  | 'ID';

export interface ShopifyProductsSearchBody {
  query?: string;
  first?: number;
  last?: number;
  before?: String;
  after?: String;
  sortBy?: ProductsSortBy;
  reverse?: Boolean;
  disableTotalCounts?: boolean;
}

const shopifyProductsSearch = async (req: NextRequest) => {
  try {
    const json = await req.json();

    const { disableTotalCounts, ...fetchBody } = json;

    let response = await shopifyStoreFront(productsSearchQuery, fetchBody, true);
    const { first, last, before, ...totalBody } = fetchBody;
    let totalCountRes;

    if (!disableTotalCounts) {
      let totalCounts: number[] = [];
      let endCursor: string | null = null;
      let productHandles: string[] = [];

      const totalCountHandler = async () => {
        totalCountRes = await shopifyStoreFront(
          getTotalProductsSearchQuery,
          { ...totalBody, first: 250, after: endCursor },
          true,
        );

        endCursor = totalCountRes?.products?.pageInfo?.hasNextPage
          ? totalCountRes?.products?.pageInfo?.endCursor
          : undefined;

        productHandles.push(
          cleanGraphQLResponse(totalCountRes)?.products?.map(
            ({ handle }: Partial<ProductData>) => handle,
          ),
        );

        if (totalCountRes?.products?.edges?.length > 0)
          totalCounts?.push(totalCountRes?.products?.edges?.length);

        return;
      };

      await totalCountHandler();

      while (!!endCursor && !!totalCounts) {
        await totalCountHandler();
        return;
      }

      const countsTotal = totalCounts?.reduce((partialSum: number, a: number) => partialSum + a, 0);

      totalCountRes = {
        products: countsTotal,
        pages: Math.ceil(countsTotal / fetchBody?.first ?? fetchBody?.last),
        productHandles: productHandles?.flat(),
      };
    }

    response = response
      ? {
          pageInfo: { ...response?.products?.pageInfo, totalCounts: totalCountRes ?? null },
          ...cleanGraphQLResponse(response),
        }
      : null;

    return new Response(JSON.stringify(response), {
      status: 200,
      headers: {
        'content-type': 'application/json',
      },
    });
  } catch (error) {
    apiError({
      errorInText: 'PRODUCT SEARCH',
      error,
      isEdge: true,
    });
  }
};

export const productsSearch = async (
  body: ShopifyProductsSearchBody,
  product_type: CategoryOptionsValues,
  includeUnavailable?: boolean,
): Promise<ProductSearchData | null | undefined> => {
  const pTypeQuery = product_type && product_type !== 'all' ? `${product_type.trim()} ` : '';
  const pTypeQueryRemove = (body?.query ? body?.query?.replace('-', ' ') + ' ' : '').replace(
    pTypeQuery,
    '',
  );

  try {
    const response = await fetch(`${BASE_URL}/api/shopify/products/products-search`, {
      method: 'POST',
      body: JSON.stringify({
        ...body,
        query: `${pTypeQuery}${pTypeQueryRemove}${
          includeUnavailable ? '' : ' available_for_sale:true '
        }${' tag_not:"exclude in search"  variants.price:>=1'}`
          .trimStart()
          .trim(),
        sortBy: body?.sortBy ?? 'UPDATED_AT',
        reverse: body?.reverse ?? true,
      } as ShopifyProductsSearchBody),
    });

    const resJson = response?.ok && (await response?.json());

    const products =
      resJson?.products && resJson?.products?.length > 0
        ? transformMultiProducts(resJson?.products)
        : null;

    return { ...resJson, products } as ProductSearchData;
  } catch (e: any) {
    console.error('productsSearch failed', e);
    if (e) return e;
  }
};

export default shopifyProductsSearch;
