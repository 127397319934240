import * as React from 'react';

import { OverridableComponent, Overwrite, PolymorphicProps, Simplify } from '../../types';
import { Box, SprinklesProps } from './Box';
import { ContainerVariants, container } from './container.css';

type ContainerVariantsWithSprinkles = Simplify<Overwrite<SprinklesProps, ContainerVariants>>;

type Props = ContainerVariantsWithSprinkles & {};

type ContainerTypeMap = {
  props: Props;
  defaultComponent: 'div';
};

export type ContainerProps<Root extends React.ElementType = ContainerTypeMap['defaultComponent']> =
  PolymorphicProps<ContainerTypeMap, Root>;

const defaultElement = 'div';

function ContainerImpl(props: ContainerProps, ref: React.ForwardedRef<Element>) {
  const {
    element,
    children,
    size = 'xxl',
    paddingX = { mobile: '12px', tablet: '16px', desktop: '24px' },
    ...restProps
  } = props;
  const className = container({ size });

  return (
    <Box
      element={element ?? defaultElement}
      className={className}
      paddingX={paddingX}
      ref={ref}
      {...restProps}
    >
      {children}
    </Box>
  );
}

export const Container = React.memo(
  React.forwardRef(ContainerImpl),
) as OverridableComponent<ContainerTypeMap>;
