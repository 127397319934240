import { ReactNode, useEffect, useRef, useState } from 'react';

import { createPortal } from 'react-dom';
import { SwipeableHandlers } from 'react-swipeable';

import { Box, BoxProps, useDisclosure } from '../..';
import { backDropRecipe } from './styles.css';

export interface ScreenOverlayProps
  extends BoxProps<'div'>,
    Pick<ReturnType<typeof useDisclosure>, 'isOpen' | 'onClose'> {
  children: ReactNode;
  swipeHandlers?: SwipeableHandlers;
  disableScrollToTop?: boolean;
}

const ScreenOverlay = ({
  isOpen,
  onClose,
  children,
  swipeHandlers,
  disableScrollToTop,
  ...props
}: ScreenOverlayProps) => {
  const backDropClassName = backDropRecipe({ isOpen });

  const ref = useRef<HTMLBodyElement | null>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document.querySelector('body');
    setMounted(true);
  }, []);

  useEffect(() => {
    if (ref?.current)
      isOpen
        ? (ref.current.style.overflowY = 'hidden')
        : ref.current.style.removeProperty('overflow-y');
    if (isOpen && !disableScrollToTop)
      window?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
  }, [isOpen]);

  if (mounted)
    return createPortal(
      <Box {...props} {...swipeHandlers} zIndex="popover">
        <Box className={backDropClassName} onClick={onClose} onTouchStart={onClose} />
        {children}
      </Box>,
      document?.querySelector('#portal') as Element,
    );

  return null;
};

export default ScreenOverlay;
