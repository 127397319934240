import * as React from 'react';

import { Sprinkles } from '../../sprinkles.css';
import { OverridableComponent, Overwrite, PolymorphicProps, Simplify } from '../../types';
import { Box, SprinklesProps } from './Box';
import { StackVariants, stackRecipe } from './stack.css';

type StackVariantsWithSprinkles = Simplify<Overwrite<SprinklesProps, StackVariants>>;

type Props = StackVariantsWithSprinkles & {
  spacing?: Sprinkles['gap'];
  direction?: Sprinkles['flexDirection'];
  align?: Sprinkles['alignItems'];
  justify?: Sprinkles['justifyContent'];
};

type StackTypeMap = {
  props: Props;
  defaultComponent: 'div';
};

export type StackProps<Root extends React.ElementType = StackTypeMap['defaultComponent']> =
  PolymorphicProps<StackTypeMap, Root>;

function StackImpl(props: StackProps, ref: React.ForwardedRef<Element>) {
  const {
    element,
    variant,
    spacing = '8px',
    align = 'stretch',
    justify = 'flex-start',
    direction = 'column',
    className,
    children,
    ...restProps
  } = props;

  let _className = stackRecipe({ variant });

  return (
    <Box
      element={element}
      ref={ref}
      display="flex"
      flexDirection={direction}
      className={[_className, className].join(' ')}
      gap={spacing}
      alignItems={align}
      justifyContent={justify}
      {...restProps}
    >
      {children}
    </Box>
  );
}

export const Stack = React.memo(React.forwardRef(StackImpl)) as OverridableComponent<StackTypeMap>;
