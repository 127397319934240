import { NextApiRequest, NextApiResponse } from 'next';

import { BASE_URL, apiError } from '../../../pages/api/utils';
import {
  CHECKOUT_ERROR_FRAGMENT,
  CHECKOUT_FRAGMENT,
  ShopifyFragmentsNames,
  shopifyStoreFront,
  transformCheckout,
} from '../../../pages/api/utils/shopifyApi';
import { CheckoutRes } from '../../../types/store';

const removeLineItemQuery = `
${CHECKOUT_FRAGMENT}
${CHECKOUT_ERROR_FRAGMENT}
mutation checkoutLineItemsRemove($checkoutId: ID!, $lineItemIds: [ID!]!) {
  checkoutLineItemsRemove(checkoutId: $checkoutId, lineItemIds: $lineItemIds) {
    checkout {
      ...${ShopifyFragmentsNames.CHECKOUT_FRAGMENT}
    }
    checkoutUserErrors {
      ...${ShopifyFragmentsNames.CHECKOUT_ERROR_FRAGMENT}
    }  
  }
}
`;

const shopifyRemoveLineItem = async (req: NextApiRequest, res: NextApiResponse) => {
  const { body } = req;

  try {
    let response = await shopifyStoreFront(removeLineItemQuery, JSON.parse(body));

    res.status(200);
    res.json(transformCheckout(response?.checkoutLineItemsRemove));
  } catch (error) {
    apiError({
      errorInText: 'remove line item - checkout',
      error,
      res,
      resJsonKey: 'checkoutUserErrors',
    });
  }
};

export interface RemoveLineItemBody {
  checkoutId: string;
  lineItemIds: string[];
}

export const removeLineItem = async (
  body: RemoveLineItemBody,
): Promise<CheckoutRes | undefined> => {
  try {
    const response = await fetch(`${BASE_URL}/api/shopify/checkout/remove-line-item`, {
      method: 'POST',
      body: JSON.stringify(body),
    });

    const resJson = await response?.json();

    return resJson;
  } catch (e) {
    console.error('removeLineItem failed', e);
  }
};

export default shopifyRemoveLineItem;
